import React from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const CeoCtc = () => {
  return (
    <section className={styles.container}>
      <div className={styles.containerCeo}>
        <img
          className={styles.ceo}
          src="./home/ceo-ctc/CEO-CTC.webp"
          alt="María Vélez - CEO Crack the Code"
        />
        <Typography className={cx(styles.position)} tag="p">
          María Vélez - CEO Crack the Code
        </Typography>
      </div>
      <div className={styles.aboutCompany}>
        <img
          className={styles.icon}
          src="./home/enhance-skills/card-type-business/icon-circle.png"
          alt="icon"
        />
        <Typography className={cx(styles.description)} tag="p">
          "En Crack The Code nos dedicamos con compromiso a capacitar a la juventud,
          brindándoles oportunidades de vida más amplias y enriquecedoras"
        </Typography>
        <Typography className={cx(styles.position)} tag="p">
          María Vélez - CEO Crack the Code
        </Typography>
      </div>
    </section>
  );
};

export default CeoCtc;
